<template>
  <div id="footer">
    <footer class="footer">
      <p class="poweredby">Crafted with ❤️ by <a target="_blank" href="https://twitter.com/thoomyorke">Thomas Seropian</a> between London and Paris.</p>
    </footer>  
  </div>
</template>
<style>

</style>
<script>
    export default {
        mounted() {
          }
    }
</script>